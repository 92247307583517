import React, { useState, ReactNode, ReactElement, CSSProperties } from "react";
import styled from "styled-components";
import { COLORS } from "../styles/colors";
import { minWidthMedia } from "../styles/mediaQueries";

type StyleProps = { isActive: boolean; customStyle?: CSSProperties };

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 100%;
`;

const AccordionItem = styled.div<StyleProps>`
  text-align: justify;

  &:first-child div:first-child {
    border-radius: 5px 5px 0 0;
  }

  &:last-child div:first-child {
    border-radius: 0 0 ${({ isActive }) => (isActive ? "0 0" : "5px 5px")};
    transiction: 0.5s ease;
  }

  &:last-child div:last-child {
    border-radius: 0 0 5px 5px;
  }
`;

const AccordionHeader = styled.div`
  background-color: ${COLORS.DARK_ORANGE};
  cursor: pointer;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;

  &:hover {
    background-color: ${COLORS.MID_ORANGE};
  }
`;

const AccordionIndicator = styled.span`
  font-weight: bold;
`;

const AccordionContent = styled.div<StyleProps>`
  overflow: hidden;
  padding: ${({ customStyle }) => customStyle?.padding ?? 10}px;
  border: 0.5px solid ${COLORS.DARK_ORANGE};
  height: ${({ isActive }) => (isActive ? "auto" : 0)};
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  visibility: ${({ isActive }) => (isActive ? "visible" : "hidden")};
  ${({ isActive }) =>
    isActive ? "" : "padding-top: 0 !important; padding-bottom: 0 !important;"}
  transition: 0.5s ease;

  ${minWidthMedia.sm} {
    padding: ${({ customStyle }) => customStyle?.padding ?? 20}px;
  }

  ${minWidthMedia.md} {
    padding: ${({ customStyle }) => customStyle?.padding ?? 30}px;
  }
`;

const SectionDiv = styled.div`
  & b {
    color: ${COLORS.MID_ORANGE};
  }

  & img {
    display: block;
    margin: auto;
    width: 100%;
  }
`;

export const ResponsiveImg = styled.img`
  padding-bottom: 20px;

  ${minWidthMedia.sm} {
    width: 60% !important;
  }
`;

type SectionProps = {
  header: string;
  children: ReactNode;
};

export const Section: React.FC<SectionProps> = ({ children }) => {
  return <SectionDiv>{children}</SectionDiv>;
};

type Props = {
  children: ReactElement<SectionProps>[];
  active?: number;
  customStyles?: Record<number, CSSProperties>;
};

const Accordion: React.FC<Props> = ({ children, active = 0, customStyles }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(active);

  const handleToggle = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <AccordionContainer>
      {React.Children.map(children, (child, index) => {
        const isActive = activeIndex === index;
        const customStyle = customStyles?.[index] || {};
        return (
          <AccordionItem isActive={isActive}>
            <AccordionHeader onClick={() => handleToggle(index)}>
              {child.props.header}
              <AccordionIndicator>{isActive ? "-" : "+"}</AccordionIndicator>
            </AccordionHeader>
            <AccordionContent isActive={isActive} customStyle={customStyle}>
              {child}
            </AccordionContent>
          </AccordionItem>
        );
      })}
    </AccordionContainer>
  );
};

export default Accordion;
